<div
  class="bulk-import-dialog"
  fxLayout="column"
  fxLayoutAlign="space-between start">
  <div fxLayout="row" fxLayoutAlign="start" class="title">
    <span>{{ translations.manageAssets.title | translate }}</span>
  </div>
  <div fxLayout="row" fxLayoutGap="24px" class="file-upload-container">
    <div [fxFlex]="file ? '100' : '40'" class="body-file-upload">
      <app-file-upload
        (fileUploadEmit)="fileUploadEmit($event)"></app-file-upload>
    </div>
    <div
      *ngIf="!file"
      class="description"
      fxFlex="60"
      fxLayout="column"
      fxLayoutAlign="space-between">
      <span>
        {{ translations.manageAssets.bodyDescriptionStart | translate }}
        <a [href]="getFilePathDownload()" download
          >{{translations.manageAssets.clickHear | translate}}</a
        >
        {{translations.manageAssets.bodyDescriptionEnd | translate}}
      </span>
      <br />
      <div fxLayout="row" fxLayoutAlign="space-between">
        <a
          mat-stroked-button
          color="accent"
          class="link-btn-text"
          [href]="getFilePathDownload()"
          download>
          {{translations.manageAssets.downloadTemplateBtn | translate}}
        </a>
        <a
          mat-stroked-button
          color="accent"
          class="link-btn-text"
          (click)="downloadAssetList()">
          {{translations.manageAssets.downloadAssetsBtn | translate}}
        </a>
      </div>
      <app-bulk-asset-import-requirements></app-bulk-asset-import-requirements>
    </div>
  </div>

  <div
    class="button-container"
    fxFill
    fxLayout="row"
    fxLayoutAlign="end end"
    fxLayoutGap="10px">
    <button mat-stroked-button color="accent" (click)="onCancel()">
      {{ translations.uploadDialog.textCancelButton | translate }}
    </button>
    <button
      [disabled]="!file || (uploadInProgress$ | async)"
      class="upload-button"
      mat-raised-button
      type="submit"
      color="accent"
      (click)="onUpload()">
      <mat-icon *ngIf="uploadInProgress$ | async"
        ><mat-spinner diameter="24"></mat-spinner
      ></mat-icon>
      {{ translations.uploadDialog.textUploadButton | translate }}
    </button>
  </div>
</div>
