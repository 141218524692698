import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Translations } from '@services/i18n/translations.service';
import { TranslateService } from '@zonar-ui/i18n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-asset-import-requirements',
  templateUrl: './bulk-asset-import-requirements.component.html',
  styleUrls: ['./bulk-asset-import-requirements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkAssetImportRequirementsComponent implements OnInit {
  guideList = [];
  guideDesc = {};
  urlDownload = '';
  private _translated;
  private onDestroy$ = new Subject<void>();

  constructor(
    public translateService: TranslateService,
    public translations: Translations
  ) {
    this.translateService
      .get([
        this.translations.overview.table.headings.assetName,
        this.translations.overview.table.headings.lastScanStatus,
        this.translations.overview.table.headings.nextDeadline,
        this.translations.overview.table.headings.scanCount,
        this.translations.overview.table.headings.lastScanDate,
        this.translations.overview.table.headings.status,
        this.translations.overview.table.headings.vin,

        this.translations.supportModule.importGuide.asset.guideDescription
          .subDescPrefix,
        this.translations.supportModule.importGuide.asset.guideDescription
          .subDescSuffix,
        this.translations.supportModule.importGuide.asset.fields.vinFieldName,
        this.translations.supportModule.importGuide.asset.fields
          .vinFieldRequirement,
        this.translations.supportModule.importGuide.asset.fields
          .registeredStateFieldName,
        this.translations.supportModule.importGuide.asset.fields
          .registeredStateRequirement,
        this.translations.supportModule.importGuide.asset.fields
          .registeredCountryFieldName,
        this.translations.supportModule.importGuide.asset.fields
          .registeredCountryRequirement,
        this.translations.supportModule.importGuide.asset.fields
          .registrationExpDateFieldName,
        this.translations.supportModule.importGuide.asset.fields
          .registrationExpDateFieldRequirement,
        this.translations.supportModule.importGuide.asset.fields
          .enrolledFlagFieldName,
        this.translations.supportModule.importGuide.asset.fields
          .enrolledFlagRequirement
      ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((_translated) => (this._translated = _translated));
  }

  ngOnInit(): void {
    this.initGuide();
  }

  initGuide() {
    this.guideDesc = {
      subDescPrefix:
        this._translated[
          this.translations.supportModule.importGuide.asset.guideDescription
            .subDescPrefix
        ],
      subDescSuffix:
        this._translated[
          this.translations.supportModule.importGuide.asset.guideDescription
            .subDescSuffix
        ]
    };
    this.guideList.push(
      {
        fieldName:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .vinFieldName
          ],
        requirement:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .vinFieldRequirement
          ],
        required: true
      },
      {
        fieldName:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .registeredStateFieldName
          ],
        requirement:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .registeredStateRequirement
          ],
        required: true
      },
      {
        fieldName:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .registeredCountryFieldName
          ],
        requirement:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .registeredCountryRequirement
          ],
        required: true
      },
      {
        fieldName:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .registrationExpDateFieldName
          ],
        requirement:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .registrationExpDateFieldRequirement
          ],
        required: true
      },
      {
        fieldName:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .enrolledFlagFieldName
          ],
        requirement:
          this._translated[
            this.translations.supportModule.importGuide.asset.fields
              .enrolledFlagRequirement
          ],
        required: true
      }
    );
  }
}
