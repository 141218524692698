export interface IAsset {
  vin: string;
  registeredState: string;
  registeredCountry: string;
  assetId?: string;
  assetName?: string;
  registrationExpirationDate?: string;
  nextDeadLine?: NextDeadlineEnum; //TODO what is the purpose of this?
  nextDeadline?: string;
  lastPhoneHome?: string;
  status?: AssetViewStatusEnum;
  gpssn?: number;
  scanCount?: number;
  lastScan?: LastScan;
  enrolled?: boolean;
}

export interface LastScan {
  status?: StatusEnum;
  errorMessage?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IScanAsset {
  vin: string[];
  registeredState?: string;
  registeredCountry?: string;
  assetId?: string;
  registrationExpirationDate?: string;
  nextDeadLine?: string;
  lastDeadLine?: string;
  lastScan?: string;
  lastScanDayCount?: number;
}

export interface IScanAssetVin {
  vin: string[];
}

export interface Scan {
  id: string;
  vin: string;
  scanStatus: StatusEnum;
  scanCreated: string;
  lastModified?: string;
  reportFilename?: string;
  assetDetails?: Asset;
  downloadInProgress?: boolean;
}

export interface Asset {
  assetName?: string;
}

export enum Status {
  PENDING,
  FAIL,
  PASS
}

export enum StatusEnum {
  REQUESTED = 'requested',
  PASS = 'pass',
  FAIL = 'fail',
  ERROR = 'error',
  COMPLIANT = 'compliant',
  SUBMITTED = 'submitted',
  NOT_COMPLIANT = 'not_compliant',
  NOT_REGISTERED = 'not_registered',
  WAITING = 'waiting',
  COMPLETE = 'complete',
  UNKNOWN = 'UNKNOWN',
  PENDING = 'PENDING'
}

export enum AssetViewStatusEnum {
  COMPLIANT = 'compliant',
  NOT_COMPLIANT = 'not_compliant',
  NOT_REGISTERED = 'not_registered',
  WAITING = 'waiting'
}

export enum NextDeadlineEnum {
  WITHIN_7_DAYS = 'within_7_days',
  WITHIN_30_DAYS = 'within_30_days',
  WITHIN_90_DAYS = 'within_90_days'
}

export enum AssetEventTypes {
  VERIFY = 'verify_event',
  SCAN = 'scan_event'
}
