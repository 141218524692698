export interface ParamsModel {
  page?: number;
  per_page?: number | string;
  asset_id?: Array<string>;
  division_id?: Array<string>;
  asset_status?: Array<string>;
  vin?: Array<string>;
  last_scan_status?: Array<string>;
  next_deadline_from?: string;
  next_deadline_to?: string;
  sort_order?: string;
  sort_by?: string;
  start_time?: string;
  csv_filename?: string;
  timezone?: string;

  end_time?: string;
  divisionId?: Array<string>;
  paginate?: boolean;
  event_type?: string;
  company_id?: string;
  viewBy?: string;

  lastModifiedEndTime?: string;
  lastModifiedStartTime?: string;
  next_deadline?: string;
  scanEndTime?: string;
  scanStartTime?: string;
  assetName?: string;
  assetStatus?: string;
  lastScanDate?: string;
  sort?: string;
  created_at_from?: string;
  created_at_to?: string;
  enrolled?: Enrolled;
  all_assets_csv_flag?: boolean;
}

export enum Enrolled {
  ALL = 'ALL',
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

export interface CurrentUrlParams {
  [x: string]: any;
}
