<div class="overview-container">
  <app-page-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h1>
        {{ translateService.get(translations?.overview.pageTitle) | async |
        translate }}
      </h1>
      <div *ngIf="hasRequestManageAssetsPermission$ | async" fxHide.lt-md>
        <button
          mat-flat-button
          class="manage-assets-btn"
          (click)="importCsv()"
          color="accent">
          <mat-icon>settings</mat-icon>
          {{ translateService.get(translations.manageAssets.title) | async |
          translate }}
        </button>
      </div>
    </div>
  </app-page-title>

  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      *ngFor="let link of navLinks"
      mat-tab-link
      #rla="routerLinkActive"
      [active]="activeLink === link"
      [routerLink]="link.path"
      queryParamsHandling="preserve"
      routerLinkActive>
      {{ translateService.get(translations.overview.matTabs[link.label]) | async
      | translate }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
