export const idleTableOrder = [
  'assetName',
  'severity',
  'latestDate',
  'totalEvents',
  'manufacturer',
  'divisionName',
  'avgHrs',
  'totalHrs',
  'idleFuel',
  'idleCost',
  'vin'
];

export const usageTableOrder = [
  'assetName',
  'startTs',
  'endTs',
  'manufacturer',
  'endEngineHour',
  'endOdometer',
  'totalEngineHour',
  'totalMiles',
  'fuelEfficiency',
  'totalTrip',
  'totalFuel'
];

export enum fileNamePrefix {
  ASSETS = 'Emissions-Check-Assets-Report-',
  EVENTS = 'Emissions-Check-Events-Report-'
}
