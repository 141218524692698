import { Injectable } from '@angular/core';
import { IAsset } from '@models/asset-model';
import { environment, environment as env } from '@environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SelectedCompanyService } from '@services/selected-company/selected-company.service';
import { ParamsModel } from '@models/params-model';
import {
  filterParamsModelFromEventsToAsset,
  filterParamsModelFromAssetToEvents
} from './common/common-functions';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EmissionsCheckApiService {
  private apiUrl = environment.apiBase.url;
  constructor(
    private http: HttpClient,
    private selectedCompanyService: SelectedCompanyService
  ) {}

  createAsset(params: IAsset) {
    return this.selectedCompanyService.getCompanyId().pipe(
      switchMap((id) => {
        const url = `${this.apiUrl}/assets/${id}`;
        return this.http.post(url, [params], { observe: 'response' });
      })
    );
  }

  importAssets(f: File) {
    const file = new FormData();
    file.append('file', f, f.name);
    return this.selectedCompanyService.getCompanyId().pipe(
      switchMap((id) => {
        const url = `${this.apiUrl}/assets/${id}/from_csv`;
        return this.http.post(url, file, { observe: 'response' });
      })
    );
  }

  scanAssets(params: string[], companyId: string) {
    const url = `${this.apiUrl}/scan/${companyId}`;
    return this.http.post(url, params, { observe: 'response' });
  }

  scanAsset(vin: string) {
    return this.selectedCompanyService.getCompanyId().pipe(
      switchMap((id) => {
        const url = `${this.apiUrl}/scan/${id}`;
        return this.http.post(url, [vin], { observe: 'response' });
      })
    );
  }

  getAssets(paramsSent: ParamsModel): Observable<any> {
    const filteredParams = filterParamsModelFromEventsToAsset(paramsSent);
    const url = `${this.apiUrl}/assets/${filteredParams.company_id}`;
    const params = this.createParams(filteredParams);

    return this.http.get<any>(url, { params, observe: 'response' });
  }

  getEvents(paramsSent: ParamsModel): Observable<any> {
    const filteredParams = filterParamsModelFromAssetToEvents(paramsSent);
    const url = `${this.apiUrl}/scans/${filteredParams.company_id}`;
    const params = this.createParams(filteredParams);

    return this.http.get<any>(url, { params, observe: 'response' });
  }

  createParams(selection: ParamsModel): Params {
    const queryParams: Params = {};
    Object.keys(selection).forEach((key) => {
      const value = selection[key];
      if (!value) {
        delete queryParams[key];
      } else {
        queryParams[key] = value;
      }
    });
    return queryParams;
  }
}
